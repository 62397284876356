import {Component, OnInit} from '@angular/core';
import {IAccount} from './account-service-response';
import {AccountService} from './account.service';

@Component({
    selector: 'app-account-list',
    templateUrl: './account-list.component.html',
    styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {
    pageTitle = 'Accounts';
    accounts: IAccount[];
    errorMessage: string;

    constructor(private accountService: AccountService) {
    }

    ngOnInit(): void {
      this.getAccounts();
    }

    getAccounts() {
      function compare(a: IAccount, b: IAccount) {
        let comparison = 0;
        if (a.name > b.name) {
          comparison = 1;
        } else if (a.name < b.name) {
          comparison = -1;
        }
        return comparison;
      }

      this.accountService.getAccounts().subscribe({
        next: accounts => {
          this.accounts = accounts;
          this.accounts.sort(compare);
        },
        error: err => this.errorMessage = err
      });
    }

}
