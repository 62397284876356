import { Injectable } from '@angular/core';
import { Location } from '@angular/common'
import {AppConfig} from '../app.config';
import {LoginService} from './login.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  private readonly endpoint: string = '';

  constructor(private httpClient: HttpClient, private config: AppConfig, private loginService: LoginService) {
    for (const cloudLogicCustom of config.amplifyConfig.aws_cloud_logic_custom) {
      if (cloudLogicCustom.name.endsWith('API')) {
        this.endpoint = cloudLogicCustom.endpoint;
      }
    }
  }

  private generateOptions(requestOptions: any): any {
    const standardOptions: any = {
      headers: {
        Authorization: this.loginService.getCurrentToken()
      }
    };
    if (requestOptions && requestOptions.headers) {
      const combinedHeaders: any = { ...requestOptions.headers, ...standardOptions.headers };
      return { ...requestOptions, ...standardOptions, ...{'headers': combinedHeaders} };
    }
    return { ...requestOptions, ...standardOptions };
  }

  get<T>(path: string, options?: any): Observable<any> {
    const combinedOptions = this.generateOptions(options);
    const requestUrl = Location.joinWithSlash(this.endpoint, path);
    return this.httpClient.get(requestUrl, combinedOptions);
  }

  put<T>(path: string, body: any, options?: any): Observable<any> {
    const combinedOptions = this.generateOptions(options);
    const requestUrl = Location.joinWithSlash(this.endpoint, path);
    return this.httpClient.put(requestUrl, body, combinedOptions);
  }

  post<T>(path: string, body: any, options?: any): Observable<any> {
    const combinedOptions = this.generateOptions(options);
    const requestUrl = Location.joinWithSlash(this.endpoint, path);
    return this.httpClient.post(requestUrl, body, combinedOptions);
  }
}
