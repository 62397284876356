import { Injectable } from '@angular/core';
import {ApiClientService} from '../../services/api-client.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Location} from '@angular/common';
import {catchError, tap} from 'rxjs/operators';
import {IEbApplicationStage} from './eb-environment-service-response';

@Injectable({
  providedIn: 'root'
})
export class EbEnvironmentService {

  private apiPath = '/elasticbeanstalk/environment';

  constructor(private apiClient: ApiClientService) {
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred ${err.error.message}`;
    } else {
      errorMessage = `Function returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  getEnvironments(applicationName: string): Observable<IEbApplicationStage[]> {
    const path: string = Location.joinWithSlash(this.apiPath, applicationName);
    return this.apiClient.get<IEbApplicationStage>(path).pipe(
      tap(data => console.log('All: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }
}
