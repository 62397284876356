import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

import {IEbApplication} from './eb-application-service-response';
import {ApiClientService} from '../services/api-client.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EbApplicationService {
  private apiPath = '/elasticbeanstalk/application';

  constructor(private apiClient: ApiClientService) {
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred ${err.error.message}`;
    } else {
      errorMessage = `Function returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  getEbApplications(): Observable<IEbApplication[]> {
    return this.apiClient.get<IEbApplication>(this.apiPath).pipe(
      tap(data => console.log('All: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  getEbApplication(applicationName: string): Observable<IEbApplication | undefined> {
    return this.getEbApplications()
      .pipe(
        map(
          (applications: IEbApplication[]) =>
            applications.find(a => a.applicationName === applicationName)
        )
      );
  }
}
