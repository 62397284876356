import { Injectable } from '@angular/core';
import {ApiClientService} from '../../services/api-client.service';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { Location } from '@angular/common';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EbAppVersionSyncService {

  private apiPath = '/synchronize/elasticbeanstalk/application/version';

  constructor(private apiClient: ApiClientService) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred ${err.error.message}`;
    } else {
      errorMessage = `Function returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  synchronizeRecentVersions(applicationName: string): Observable<any> {
    let path = Location.joinWithSlash(this.apiPath, applicationName);
    return this.apiClient.put(path, {}).pipe(
      tap(data => console.log('All: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  synchronizeVersion(applicationName: string, version: string): Observable<any> {
    let path: string = Location.joinWithSlash(this.apiPath, applicationName);
    let httpParams: HttpParams = new HttpParams().set('version', version);
    return this.apiClient.put(path, {}, {params: httpParams}).pipe(
      tap(data => console.log('All: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }
}
