import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {LoginService} from '../services/login.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private loginService: LoginService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const reportedError = error.error;
        let message = '';
        if(reportedError) {
          message = reportedError.message;
        }
        if(error.status === 401 && !error.url.includes('userInfo') && message === 'The incoming token has expired') {
          console.log('The current token has expired. Requesting a new one.');
          this.loginService.login();
        }
        return throwError(error);
      })
    ) as Observable<HttpEvent<any>>;
  }
}