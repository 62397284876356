import {Injectable} from '@angular/core';
import {ApiClientService} from '../services/api-client.service';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MavenService {
  private apiPath = '/maven/';

  constructor(
    private apiClient: ApiClientService
  ) {}

  list(repository: string, group: string, artifact: string): Observable<any> {
    const params = new HttpParams()
      .set('repository', repository)
      .set('group', group)
      .set('artifact', artifact);

    return this.apiClient.get(this.apiPath + 'list', {params})
      .pipe(
        first()
      );
  }

  create(applicationName: string, repository: string, group: string, artifact: string, version: string, extension: string): Observable<any> {
    const payload = {
      repository,
      group,
      artifact,
      version,
      extension
    };
    const params = new HttpParams()
      .set('applicationName', applicationName);

    return this.apiClient.post(this.apiPath + 'upload', payload, {params})
      .pipe(
        first()
      );
  }
}
