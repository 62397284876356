import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {IMetadata} from '../shared/metadata';
import {Observable, of, throwError} from 'rxjs';
import {catchError, first, map, shareReplay, tap} from 'rxjs/operators';
import {LoginService} from './login.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  private metadata: Observable<IMetadata>;

  constructor(
    private http: HttpClient,
    private loginService: LoginService
  ) {}

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred ${err.error.message}`;
    } else {
      errorMessage = `Function returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  get(): Observable<IMetadata> {
    return this.http.get<IMetadata>('assets/metadata.json', {
      headers: {
        Authorization: this.loginService.getCurrentToken()
      }
    }).pipe(
      tap(data => console.log('All: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }
}
