import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {Location} from '@angular/common';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {ApiClientService} from '../../services/api-client.service';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EbAppVersionUploadService {
  private apiPath = '/elasticbeanstalk/artifact';

  constructor(private apiClient: ApiClientService, private httpClient: HttpClient) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred ${err.error.message}`;
    } else {
      errorMessage = `Function returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  putApplicationVersionToS3(s3Url: string, file: File) {
    let httpOptions = {}
    if (file.name.endsWith('.zip')) {
      httpOptions = {
        headers: {
          'Content-Type': 'application/zip'
        }
      }
    }
    return this.httpClient.put(s3Url, file, httpOptions).pipe(
      tap(() => console.log('Posted ' + file.name + ' to S3.'))
    );
  }

  putApplicationVersion(applicationName: string, file: File): Observable<any> {
    if (file.name.endsWith('.zip') || file.name.endsWith('.war')) {
      const requestPath = Location.joinWithSlash(this.apiPath, Location.joinWithSlash(applicationName, file.name));
      return this.apiClient.put(requestPath, null).pipe(
        mergeMap(lambdaResponse => {
          const signedUrl = lambdaResponse.signed_url;
          return this.putApplicationVersionToS3(signedUrl, file);
        }),
        catchError(this.handleError)
      );
    } else {
      return throwError(new Error('Unsupported file type.'));
    }
  }
}
