import { Injectable } from '@angular/core';
import {LoginService} from './login.service';
import {ApiClientService} from './api-client.service';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IsAuthorizedService {

  private apiPath = '/authorized';

  constructor(private loginService: LoginService, private apiClient: ApiClientService) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred ${err.error.message}`;
    } else {
      errorMessage = `Function returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  canActiveUserWriteForApplication(applicationName: string): Observable<boolean> {
    let httpParams: HttpParams = new HttpParams().set('applicationName', applicationName);
    return this.apiClient.get(this.apiPath, {params: httpParams}).pipe(
      tap(data => console.log('All: ' + JSON.stringify(data))),
      map(response => response.authorized),
      catchError(this.handleError)
    );
  }
}
