import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EbApplicationListComponent} from './eb-application-list.component';
import {EbApplicationDetailComponent} from './eb-application-detail.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [EbApplicationListComponent, EbApplicationDetailComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class EbApplicationsModule {
}
