import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IVersion} from '../shared/version';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {SessionStorageService} from '../services/session-storage.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  pageTitle = 'Home';
  version: string;
  versionObservable: Observable<string>;
  private endPoint = '';
  private apiUrl = this.endPoint + 'assets/version.json';

  private applicationNames: string[] = [];

  constructor(private http: HttpClient,
              private sessionStorage: SessionStorageService) { }

  ngOnInit() {
    this.version = this.sessionStorage.getItem('babbage-version');
  }

  getVersion(): Observable<string> {
    if (this.version) {
      return of(this.version);
    } else if (this.versionObservable) {
      return this.versionObservable;
    } else {
      this.versionObservable = this.http.get<IVersion>(this.apiUrl).pipe(
        map(response => {
          this.version = response.version;
          this.sessionStorage.setItem('babbage-version', this.version);
          return this.version;
        })
      );
      return this.versionObservable;
    }
  }
}
