import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {ApiClientService} from '../services/api-client.service';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EbDeploymentService {

  private apiPath = '/elasticbeanstalk/deployment';

  constructor(private apiClient: ApiClientService) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred ${err.error.message}`;
    } else {
      errorMessage = `Function returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  deployApplicationVersion(applicationName: string, tenantType: string, versionLabel: string,
                           accountName: string, partition: string): Observable<any> {
    const requestPath = Location.joinWithSlash(this.apiPath,
      Location.joinWithSlash(applicationName,
        Location.joinWithSlash(accountName,
          Location.joinWithSlash(partition,
            Location.joinWithSlash(tenantType, versionLabel)
          )
        )
      )
    );
    return this.apiClient.put(requestPath, {}).pipe(
      tap(data => console.log('All: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }
}
