import {Injectable} from '@angular/core';
import {ApiClientService} from '../services/api-client.service';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  private apiPath = '/database/';

  constructor(
    private apiClient: ApiClientService
  ) {}

  list(applicationName: string): Observable<any> {
    const params = new HttpParams()
      .set('applicationName', applicationName);

    // return new Observable((observer) => {
    //   observer.next([
    //     {
    //       stageIdentifier: {
    //         accountName: 'piercecountywa',
    //         partition: 'aws',
    //         tenantType: 'prev'
    //       },
    //       promotionTarget: {
    //         accountName: 'fdis',
    //         partition: 'aws',
    //         tenantType: 'prev'
    //       },
    //       environments: [
    //         {
    //           tenantName: 'test',
    //           currentVersion: '1.2.3',
    //           scriptId: 'abcdefg',
    //           lastUpdated: '2022-10-28T16:28:48.174000+00:00',
    //           status: 'Available',
    //           arn: 'arn:aws:rds:us-west-2:571077567354:cluster:rds-mysql-fdis-prev-cluster'
    //         }
    //       ]
    //     },
    //     {
    //       stageIdentifier: {
    //         accountName: 'fdis',
    //         partition: 'aws',
    //         tenantType: 'prev'
    //       },
    //       promotionTarget: null,
    //       environments: [
    //         {
    //           tenantName: 'pierce',
    //           currentVersion: '1.2.4',
    //           scriptId: 'abcdefg',
    //           lastUpdated: '2022-10-28T16:28:48.174000+00:00',
    //           status: 'Available',
    //           arn: 'arn:aws:rds:us-west-2:161483455364:cluster:rds-mysql-fdis-prev-cluster'
    //         }
    //       ]
    //     }
    //   ]);
    //   observer.complete();
    // });
    return this.apiClient.get(this.apiPath + 'list', {params})
      .pipe(
        first()
      );
  }

  deploy(applicationName: string, accountName: string, tenantType: string, partition: string,
         repository: string, group: string, artifact: string, version: string, scriptId: string, arn: string) {
    const payload = {
      accountName,
      tenantType,
      partition,
      repository,
      group,
      artifact,
      version,
      scriptId,
      arn
    };
    const params = new HttpParams()
      .set('applicationName', applicationName);

    // return new Observable((observer) => {
    //   observer.complete();
    // });
    return this.apiClient.post(this.apiPath + 'deploy', payload, {params})
      .pipe(
        first()
      );
  }

  refresh(applicationName: string, tenantName: string, stageIdentifier): Observable<any> {
    const params = new HttpParams()
      .set('applicationName', applicationName);

    const payload = {
      tenantName,
      stageIdentifier
    };

    return this.apiClient.post(this.apiPath + 'refresh', payload, {params})
      .pipe(
        first()
      );
  }
}
