import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ApplicationDatabaseDetailComponent} from './application-database-detail.component';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [ApplicationDatabaseDetailComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ApplicationDatabaseModule {
}
