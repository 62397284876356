import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';

import {APP_CONFIG, AppConfig} from './app.config';
import {AppComponent} from './app.component';

import {AppRoutingModule} from './app-routing.module';

import {WelcomeModule} from './welcome/welcome.module';
import {ModalModule} from 'ngx-bootstrap/modal';
import {EbApplicationsModule} from './eb-applications/eb-applications.module';
import {AccountsModule} from './accounts/accounts.module';
import {SharedModule} from './shared/shared.module';
import {ApplicationDatabaseModule} from './application-database/application-database.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AmplifyAngularModule,
    WelcomeModule,
    AccountsModule,
    EbApplicationsModule,
    ApplicationDatabaseModule,
    ModalModule.forRoot(),
    SharedModule
  ],
  providers: [
    AmplifyService,
    {provide: AppConfig, useValue: APP_CONFIG}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
