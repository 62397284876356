import {Injectable} from '@angular/core';
import {AppConfig} from '../app.config';
import {WindowService} from './window.service';
import {SessionStorageService} from './session-storage.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private readonly loginUrl: string = '';
  private readonly tokenUrl: string = '';
  private readonly identityUrl: string = '';
  private readonly timeoutInterval: number = (5 * 60000); // 60000 milliseconds in a minute
  private refresh_scheduled: boolean = false;


  constructor(private config: AppConfig, private windowService: WindowService,
              private sessionStorage: SessionStorageService, private httpClient: HttpClient) {
    let redirectUrl = this.getInitialRedirectUri();
    this.loginUrl = config.cloudConfig.cognito_url +
      '/oauth2/authorize?response_type=code&client_id=' + config.cloudConfig.client_id +
      '&redirect_uri=' + redirectUrl + '&identity_provider=AzureADFS';
    this.tokenUrl = config.cloudConfig.cognito_url + '/oauth2/token';
    this.identityUrl = config.cloudConfig.cognito_url + '/oauth2/userInfo'
  }

  getLoginUrl(): string {
    return this.loginUrl;
  }

  getTokenUrl(): string {
    return this.tokenUrl;
  }

  getInitialRedirectUri(): string {
    const storedUri: string = this.sessionStorage.getItem('redirectUri');
    const redirectUri: string = this.windowService.getWindowLocationHref();

    if (!storedUri || storedUri === 'null') {
      this.sessionStorage.setItem('redirectUri', redirectUri);
      return redirectUri;
    }

    return storedUri;
  }

  getParameterByName(name: string): string {
    const url = this.windowService.getWindowLocationHref();
    name = name.replace(/[\[\]]/g, '\\$&');

    if (url.split('id_token').length > 2) {
      return '';
    }

    const regex = new RegExp('[?#&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results || !results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getCurrentToken(): string {
    return this.sessionStorage.getItem('idToken');
  }

  getCurrentUserName(): string {
    return this.sessionStorage.getItem('userName');
  }

  isTokenValid(token: string): boolean {
    if (token && token.length) {
      if (token.toLowerCase() != 'null' && token.toLowerCase() != 'undefined') {
        return true;
      }
    }
    return false;
  }

  postUserIdentityRequest(): Observable<any> {
    let requestHeaders: any = {
      'headers': {
        'Authorization': `Bearer ${this.sessionStorage.getItem('accessToken')}`
      }
    }
    console.log('Request headers are ' + JSON.stringify(requestHeaders));
    return this.httpClient.get<any>(this.identityUrl, requestHeaders);
  }

  handleUserIdentityRequest() {
    this.postUserIdentityRequest().subscribe({
      next: response => {
        console.log('Successfully made call to identity endpoint.');
        this.sessionStorage.setItem('userName', response['email']);
      },
      error: response => {
        console.log('Encountered an error while trying to get user identity: ');
        console.log(JSON.stringify(response));
      }
    });
  }

  postTokenRequest(requestParams: any, requestHeaders: any) {
    this.httpClient.post<any>(this.tokenUrl, requestParams, requestHeaders).subscribe({
      next: response => {
        console.log('Successfully made call to token endpoint. Storing tokens and scheduling refresh.');
        this.sessionStorage.setItem('idToken', response['id_token']);
        this.sessionStorage.setItem('accessToken', response['access_token']);
        this.sessionStorage.setItem('refreshToken', response['refresh_token']);
        const currentTime: Date = new Date();
        const tokenExpirationTime: Date = new Date(currentTime.getTime() + response['expires_in'] * 1000);
        this.sessionStorage.setItem('token_expiration_time', String(tokenExpirationTime.getTime()));
        this.handleUserIdentityRequest();
        this.windowService.setTimeout(() => {this.handleRefresh();}, this.timeoutInterval);
        this.refresh_scheduled = true;
      },
      error: response => {
        console.log('Encountered an error while trying to get token:');
        console.log(JSON.stringify(response));
        console.log('Redirecting to login');
        this.sessionStorage.removeItem('idToken');
        this.sessionStorage.removeItem('accessToken');
        this.sessionStorage.removeItem('refreshToken');
        this.sessionStorage.removeItem('token_expiration_time');
        this.refresh_scheduled = false;
        this.login();
      }
    });
  }

  handleRefresh() {
    const refreshToken: string = this.sessionStorage.getItem('refreshToken');
    const expirationMilliseconds: number = Number(this.sessionStorage.getItem('token_expiration_time'));
    const currentTime: Date = new Date();
    if (currentTime.getTime() > expirationMilliseconds) {
      let requestHeaders: any = {
        'Content-Type': 'application/x-www-form-urlencoded'
      };
      let requestParams: HttpParams = new HttpParams()
        .set('grant_type', 'refresh_token')
        .set('client_id', this.config.cloudConfig.client_id)
        .set('refresh_token', refreshToken);

      if (this.isTokenValid(refreshToken)) {
        this.postTokenRequest(requestParams, requestHeaders);
      }
    } else {
        console.log('Token is not expired yet. Scheduling a time to check later.');
        this.windowService.setTimeout(() => {
          this.handleRefresh();
        }, this.timeoutInterval);
        this.refresh_scheduled = true;
    }
  }

  requestTokens(authCode: string) {
    let requestHeaders: any = {
      'Content-Type': 'application/x-www-form-urlencoded'
    };
    let requestParams: HttpParams = new HttpParams()
      .set('grant_type', 'authorization_code')
      .set('client_id', this.config.cloudConfig.client_id)
      .set('code', authCode)
      .set('redirect_uri', this.getInitialRedirectUri());

    this.postTokenRequest(requestParams, requestHeaders);
  }

  isLoggedIn(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const authorizationCode: string = this.getParameterByName('code');

      if (authorizationCode) {
        this.requestTokens(authorizationCode);
        observer.next(true);
        observer.complete();
      }

      this.postUserIdentityRequest().subscribe({
        next: response => {
          const token: string = this.getCurrentToken();
          const result: boolean = this.isTokenValid(token);
          if (!this.refresh_scheduled) {
            console.log('Token has not expired yet when checking if the user is already logged in.');
            console.log('Scheduling a time to check later.');
            this.windowService.setTimeout(() => {
              this.handleRefresh();
            }, this.timeoutInterval);
            this.refresh_scheduled = true;
          }
          observer.next(result);
          observer.complete();
        },
        error: error => {
          if (error.status && error.status === 401) {
            observer.next(false);
            observer.complete();
          }
        }
      });
    });
  }

  login(): void {
    this.isLoggedIn().subscribe({
      next: loggedIn => {
        if (!loggedIn) {
          this.windowService.setWindowLocationHref(this.loginUrl);
        }
      }
    });
  }
}
