import {Component, OnInit, TemplateRef} from '@angular/core';
import {MavenService} from '../eb-applications/maven.service';
import {MetadataService} from '../services/metadata.service';
import {IMetadata, ISystem} from '../shared/metadata';
import {ActivatedRoute} from '@angular/router';
import {IEbStageIdentifier} from '../eb-applications/environments/eb-environment-service-response';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {IsAuthorizedService} from '../services/is-authorized.service';
import {DatabaseService} from './database.service';

@Component({
  selector: 'app-application-database-detail',
  templateUrl: './application-database-detail.component.html',
  styleUrls: ['./application-database-detail.component.scss']
})
export class ApplicationDatabaseDetailComponent implements OnInit {
  applicationName: string;
  repository = 'releases';
  group: string;
  artifactName: string;
  pageTitle = 'Elastic Beanstalk Application';
  canWrite = false;
  errorMessage: string;
  metadata: IMetadata;
  system: ISystem;
  appDbVersions;
  dbStages;
  modalRef: BsModalRef;

  deployVersionLabel: string;
  deployVersion: string;
  deployScriptId: string;
  deployArn: string;
  deployTarget: IEbStageIdentifier;

  deployDbVersionSuccessMessage: string;
  deployDbVersionErrorMessage: string;
  disableDeployDbVersion = false;

  refreshSuccessMessage: string;
  refreshErrorMessage: string;
  disableRefresh = false;
  refreshTarget = {
    stage: null,
    environment: null
  };

  constructor(
    private authorizedService: IsAuthorizedService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private metadataService: MetadataService,
    private mavenService: MavenService,
    private databaseService: DatabaseService
  ) { }

  ngOnInit() {
    this.applicationName = this.route.snapshot.paramMap.get('applicationName');
    this.metadataService.get().subscribe({
      next: metadata => {
        this.metadata = metadata;
        this.system = this.metadata.systems.find(s => s.name === this.applicationName);
        this.group = this.system.artifactConfig.sql.maven.group;
        this.artifactName = this.system.artifactConfig.sql.maven.artifact;
        this.checkPermissions();
        this.getAppDbVersions();
        this.getDbStages();
      }
    });
  }

  getAppDbVersions() {
    this.appDbVersions = null;
    this.mavenService.list(this.repository, this.group, this.artifactName).subscribe({
      next: mavenListResponse => {
        this.appDbVersions = mavenListResponse.items;
      },
      error: err => this.errorMessage = err.error.message
    });
  }

  getDbStages() {
    this.dbStages = null;
    this.databaseService.list(this.applicationName).subscribe({
      next: dbStages => {
        this.dbStages = dbStages;
        this.dbStages.forEach((mainStage) => {
          const hasProdStage = this.dbStages.some((stage) => {
            return stage.stageIdentifier.accountName === mainStage.stageIdentifier.accountName &&
              stage.stageIdentifier.tenantType === 'prod';
          });
          mainStage.hasProdStage = hasProdStage;
        });
      },
      error: err => this.errorMessage = err.error.message
    });
  }

  checkPermissions() {
    this.authorizedService.canActiveUserWriteForApplication(this.applicationName).subscribe({
      next: isAuthorized => {
        this.canWrite = isAuthorized;
      },
      error: err => this.errorMessage = err.error.message
    });
  }

  openModal(template: TemplateRef<any>) {
    this.deployDbVersionSuccessMessage = '';

    this.modalRef = this.modalService.show(template);
  }

  setInitialStageDeployment(versionLabel: string, scriptId: string) {
    this.deployVersionLabel = `${this.artifactName}-${versionLabel}.sql`;
    this.deployVersion = versionLabel;
    this.deployScriptId = scriptId;
    this.deployTarget = this.dbStages[0].stageIdentifier;
    this.deployArn = this.dbStages[0].environments[0].arn;
  }

  onDeployDbVersionSubmit() {
    this.disableDeployDbVersion = true;
    this.databaseService.deploy(
      this.applicationName,
      this.deployTarget.accountName,
      this.deployTarget.tenantType,
      this.deployTarget.partition,
      this.repository,
      this.group,
      this.artifactName,
      this.deployVersion,
      this.deployScriptId,
      this.deployArn
    ).subscribe(
      () => {
        this.deployDbVersionSuccessMessage = 'Success!';
        this.disableDeployDbVersion = false;
        this.getDbStages();
      },
      err => {
        this.deployDbVersionErrorMessage = err.error.message;
        this.disableDeployDbVersion = false;
      }
    );
  }

  setPromotionDeployment(versionLabel: string, scriptId: string, promotionTarget: IEbStageIdentifier, repository: string) {
    this.deployVersionLabel = `${this.artifactName}-${versionLabel}.sql`;
    this.deployVersion = versionLabel;
    this.deployScriptId = scriptId;
    this.deployTarget = promotionTarget;
    this.repository = repository;
    for (const stage of this.dbStages) {
      if (stage.stageIdentifier.accountName === promotionTarget.accountName && stage.stageIdentifier.tenantType === promotionTarget.tenantType && stage.stageIdentifier.partition === promotionTarget.partition) {
        this.deployArn = stage.environments[0].arn;
        return;
      }
    }
  }

  validPromotionTarget(stageIndex: number) {
    const nextStageIndex = stageIndex + 1;
    if (this.dbStages[stageIndex].promotionTarget !== null && this.dbStages[nextStageIndex] != null) {
      if (this.dbStages[stageIndex].environments[0].currentVersion
        !== this.dbStages[nextStageIndex].environments[0].currentVersion) {
        return true;
      }
    }
    return false;
  }

  setRefreshTarget(stage, environment) {
    this.refreshSuccessMessage = '';
    this.refreshTarget.stage = stage;
    this.refreshTarget.environment = environment;
  }

  refreshDb(stage, environment) {
    console.log(`Refresh: ${environment.tenantName}-${stage.stageIdentifier.tenantType} in ${stage.stageIdentifier.accountName}`
      + ` from ${environment.tenantName}-prod`);
    this.disableRefresh = true;
    // don't re-enable refresh on success/error, it's a long running task
    // re-enable refresh on modal close in the html
    this.databaseService.refresh(this.applicationName, environment.tenantName, stage.stageIdentifier).subscribe(
      (resp) => {
        this.refreshSuccessMessage = resp.message;
      },
      (err) => {
        this.refreshErrorMessage = err.error.message;
      }
    );
  }
}
