export class Account {

  // tslint:disable-next-line:variable-name
  private _accountId: number;
  // tslint:disable-next-line:variable-name
  private _partition: string;
  // tslint:disable-next-line:variable-name
  private _accountAlias: string;
  // tslint:disable-next-line:variable-name
  private _awsConsoleSignInUrl: string;

  constructor(accountId: number, accountName: string, partition?: string) {
    this._accountId = accountId;
    this._accountAlias = accountName;
    this._partition = partition;
  }

  get accountAlias(): string {
    return this._accountAlias;
  }

  get awsConsoleSignInUrl(): string {
    if (this._partition !== null) {
      let consoleDomain = '';
      switch (this._partition) {
        case 'aws': {
          consoleDomain = 'aws.amazon.com';
          break;
        }
        case 'aws-us-gov': {
          consoleDomain = 'amazonaws-us-gov.com';
          break;
        }
      }
      this._awsConsoleSignInUrl = 'https://' + this.accountAlias + '.signin.' + consoleDomain + '/console';
    }
    return this._awsConsoleSignInUrl;
  }

  get partition(): string {
    return this._partition;
  }

}
