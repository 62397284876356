import { Component, OnInit } from '@angular/core';
import {IEbApplication} from './eb-application-service-response';
import {EbApplicationService} from './eb-application.service';
import {IAccount} from '../accounts/account-service-response';


@Component({
  selector: 'app-eb-application-list',
  templateUrl: './eb-application-list.component.html',
  styleUrls: ['./eb-application-list.component.scss']
})
export class EbApplicationListComponent implements OnInit {
  pageTitle = 'Applications';
  ebApplications: IEbApplication[];
  errorMessage: string;

  constructor(private ebApplicationService: EbApplicationService ) {
  }

  ngOnInit(): void {
    this.getApplications();
  }

  getApplications() {
    function compare(a: IEbApplication, b: IEbApplication) {
      let comparison = 0;
      if (a.applicationName > b.applicationName) {
        comparison = 1;
      } else if (a.applicationName < b.applicationName) {
        comparison = -1;
      }
      return comparison;
    }

    this.ebApplicationService.getEbApplications().subscribe( {
      next: ebApplications => {
        this.ebApplications = ebApplications;
        this.ebApplications.sort(compare);
      },
      error: err => this.errorMessage = err
    });
  }

}
