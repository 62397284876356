import {Component, OnInit, TemplateRef} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EbApplicationService} from './eb-application.service';
import {IEbApplication} from './eb-application-service-response';
import {EbAppVersionService} from './versions/eb-app-version.service';
import {IEbAppVersion} from './versions/eb-app-version-service-response';
import {EbEnvironmentService} from './environments/eb-environment.service';
import {IEbApplicationStage, IEbStageIdentifier} from './environments/eb-environment-service-response';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder, FormGroup} from '@angular/forms';
import {EbAppVersionUploadService} from './versions/eb-app-version-upload.service';
import {EbDeploymentService} from './eb-deployment.service';
import {IsAuthorizedService} from '../services/is-authorized.service';
import {EbAppVersionSyncService} from './versions/eb-app-version-sync.service';
import {MetadataService} from '../services/metadata.service';
import {tap} from 'rxjs/operators';
import {IMetadata, ISystem} from '../shared/metadata';
import {MavenService} from './maven.service';
import {Observable} from 'rxjs';

@Component({
  templateUrl: './eb-application-detail.component.html',
  styleUrls: ['./eb-application-detail.component.scss']
})
export class EbApplicationDetailComponent implements OnInit {
  applicationName: string;
  ebApplication: IEbApplication;
  ebAppVersions: IEbAppVersion[];
  ebAppStages: IEbApplicationStage[];
  errorMessage: string;
  pageTitle = 'Elastic Beanstalk Application';
  modalRef: BsModalRef;

  addAppVersionForm: FormGroup;
  addAppVersionErrorMessage: string;
  addAppVersionSuccessMessage: string;
  addAppVersionStatusMessage: string;
  disableAddAppVersion = false;

  deployAppVersionErrorMessage: string;
  deployAppVersionSuccessMessage: string;
  deployAppVersionStatusMessage: string;
  disableDeployAppVersion = false;

  deployVersionLabel: string;
  deployTarget: IEbStageIdentifier;

  canWrite = false;

  metadata: IMetadata;
  system: ISystem;
  artifactList$: Observable<any>;

  constructor(private route: ActivatedRoute,
              private ebApplicationService: EbApplicationService,
              private ebAppVersionService: EbAppVersionService,
              private ebEnvironmentService: EbEnvironmentService,
              private ebAppVersionUploadService: EbAppVersionUploadService,
              private ebDeploymentService: EbDeploymentService,
              private formBuilder: FormBuilder,
              private modalService: BsModalService,
              private authorizedService: IsAuthorizedService,
              private ebAppVersionSyncService: EbAppVersionSyncService,
              private metadataService: MetadataService,
              private mavenService: MavenService) { }

  ngOnInit() {
    const param = this.route.snapshot.paramMap.get('applicationName');
    if (param) {
      this.applicationName = param;
      this.getEbApplication();
      this.getEbAppVersions();
      this.getEbAppStages();
      this.checkPermissions();
    }
    this.addAppVersionForm = this.formBuilder.group({
      artifact: [''],
      repository: ['']
    });
    this.metadataService.get().subscribe({
      next: metadata => {
        this.metadata = metadata;
        this.system = this.metadata.systems.find(s => s.name === this.applicationName);
      }
    });
  }

  getEbApplication() {
    this.ebApplicationService.getEbApplication(this.applicationName).subscribe( {
      next: ebApplication => {
        this.ebApplication = ebApplication;
        this.pageTitle += ': ' + this.ebApplication.applicationName;
      },
      error: err => this.errorMessage = err
    });
  }

  getEbAppVersions() {

    this.ebAppVersions = null;
    this.ebAppVersionService.getApplicationVersions(this.applicationName).subscribe( {
      next: ebAppVersions => {
        this.ebAppVersions = ebAppVersions;
      },
      error: err => this.errorMessage = err
    });

  }

  getEbAppStages() {
    this.ebAppStages = null;
    this.ebEnvironmentService.getEnvironments(this.applicationName).subscribe( {
      next: ebAppStages => {
        this.ebAppStages = ebAppStages;
      },
      error: err => this.errorMessage = err
    });
  }

  checkPermissions() {
    this.authorizedService.canActiveUserWriteForApplication(this.applicationName).subscribe({
      next: isAuthorized => {
        this.canWrite = isAuthorized;
      },
      error: err => this.errorMessage = err
    });
  }

  openModal(template: TemplateRef<any>) {
    this.deployAppVersionSuccessMessage = '';
    this.addAppVersionSuccessMessage = '';

    this.modalRef = this.modalService.show(template);
  }

  updateMavenList() {
    if (this.addAppVersionForm.get('repository').value !== '') {
      this.addAppVersionForm.patchValue({artifact: null});
      this.artifactList$ = this.mavenService.list(
        this.addAppVersionForm.get('repository').value,
        this.system.artifactConfig.application.maven.group,
        this.system.artifactConfig.application.maven.artifact
      ).pipe(
        tap(data => this.addAppVersionForm.patchValue({artifact: data.items[0]}))
      );
    } else {
      this.artifactList$ = null;
      this.addAppVersionForm.patchValue({artifact: null});
    }
  }

  onAddAppVersionSubmit() {
    const item = this.addAppVersionForm.get('artifact').value;
    const asset = item.assets.find(a => {
      const ext = a[item.format].extension;
      return !ext.includes('pom') && !ext.includes('md5') && !ext.includes('sha1');
    });
    const payload = {
      repository: item.repository,
      group: item.group,
      artifact: item.name,
      version: item.version,
      extension: asset[item.format].extension
    };
    console.log('Selected: ');
    console.log(`Repository: ${payload.repository}`);
    console.log(`Group: ${payload.group}`);
    console.log(`Artifact: ${payload.artifact}`);
    console.log(`Version: ${payload.version}`);
    console.log(`Extension: ${payload.extension}`);

    const missingValues = Object.entries(payload).filter(a => !a[1]).map(a => a[0]);
    if (missingValues.length) {
      console.error(`The following properties are missing values: ${missingValues}`);
      this.disableAddAppVersion = false;
      this.addAppVersionForm.patchValue({artifact: null});
    }

    this.disableAddAppVersion = true;
    this.mavenService.create(
      this.applicationName,
      payload.repository,
      payload.group,
      payload.artifact,
      payload.version,
      payload.extension
    ).subscribe(
      () => {
        this.addAppVersionSuccessMessage = `Request for version '${payload.version}' to be uploaded to babbage has been sent. `
          + 'The resulting application version file should be available in about 1 minute.';
        this.addAppVersionStatusMessage = null;
        this.disableAddAppVersion = false;
      },
      err => {
        console.error(err);
        let message = 'Error';
        if (err && err.error && err.error.message) {
          message = err.error.message;
        }
        this.addAppVersionErrorMessage = message;
        this.addAppVersionStatusMessage = null;
        this.disableAddAppVersion = false;
      }
    );

  }

  clearAddAppVersionForm() {
    this.addAppVersionErrorMessage = '';
    this.addAppVersionSuccessMessage = '';
    this.addAppVersionForm.get('artifact').setValue(null);
  }

  setInitialStageDeployment(versionLabel: string) {
    this.deployVersionLabel = versionLabel;
    this.deployTarget = this.ebAppStages[0].stageIdentifier;
  }

  setPromotionDeployment(versionLabel: string, promotionTarget: IEbStageIdentifier) {
    this.deployVersionLabel = versionLabel;
    this.deployTarget = promotionTarget;
  }

  synchronizeVersion(versionLabel: string) {
    this.ebAppVersionSyncService.synchronizeVersion(this.applicationName, versionLabel).subscribe(
      () => {this.getEbAppVersions()},
      error => {this.errorMessage = error});
  }

  synchronizeRecentVersions() {
    this.ebAppVersionSyncService.synchronizeRecentVersions(this.applicationName).subscribe(
      () => {this.getEbAppVersions()},
      error => {this.errorMessage = error});
  }

  onDeployAppVersionSubmit() {
    this.disableDeployAppVersion = true;
    this.ebDeploymentService.deployApplicationVersion(
      this.applicationName,
      this.deployTarget.tenantType,
      this.deployVersionLabel,
      this.deployTarget.accountName,
      this.deployTarget.partition
      ).subscribe(
      () => {
        this.deployAppVersionSuccessMessage = 'Success!';
        this.deployAppVersionStatusMessage = null;
        this.disableDeployAppVersion = false;
        this.getEbAppStages();
      },
      err => {
        this.addAppVersionErrorMessage = err;
        this.deployAppVersionStatusMessage = null;
        this.disableDeployAppVersion = false;
      }
    );
  }

  validPromotionTarget(stageIndex: number) {
    const nextStageIndex = stageIndex + 1;
    if (this.ebAppStages[stageIndex].promotionTarget !== null && this.ebAppStages[nextStageIndex] != null) {
      if (this.ebAppStages[stageIndex].environments[0].currentVersion
          !== this.ebAppStages[nextStageIndex].environments[0].currentVersion) {
        return true;
      }
    }
    return false;
  }
}
