import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {LoginService} from '../services/login.service';

import {IAccount} from './account-service-response';
import {IMetadata} from '../shared/metadata';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private endPoint = '';
  private apiUrl = this.endPoint + 'assets/metadata.json';

  constructor(private http: HttpClient, private loginService: LoginService) {
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred ${err.error.message}`;
    } else {
      errorMessage = `Function returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  getAccounts(): Observable<IAccount[]> {
    return this.http.get<IMetadata>(this.apiUrl, {
      headers: {
        Authorization: this.loginService.getCurrentToken()
      }
    }).pipe(
      map(event => (event.accounts)),
      tap(data => console.log('All: ' + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  getAccount(id: number): Observable<IAccount | undefined> {
    return this.getAccounts()
      .pipe(
        map((accounts: IAccount[]) => accounts.find(a => a.id === id))
      );
  }
}

