import amplify from '../aws-exports';
import cloudconfig from '../cloud-exports';

export class AppConfig {
  amplifyConfig: any;
  cloudConfig: any;
}

export const APP_CONFIG: AppConfig = {
  amplifyConfig: amplify,
  cloudConfig: cloudconfig
};
