import {Component, OnInit} from '@angular/core';
import {IAccount} from './account-service-response';
import {AccountService} from './account.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Account} from './account';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {
  pageTitle = 'Account';
  errorMessage: string;
  account: IAccount;
  accountAlias: string;
  accountInstance: Account;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private accountService: AccountService) {
  }

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('id');
    if (param) {
      const id = +param;
      this.getAccount(id);
    }
  }

  getAccount(id: number) {
    this.accountService.getAccount(id).subscribe({
      next: account => {
        this.account = account;
        this.accountInstance = new Account(this.account.id, this.account.name, this.account.partition);
        this.pageTitle += ': ' + this.account.name + '/' + this.account.partition;
      },
      error: err => this.errorMessage = err
    });
  }

}

