import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor() { }

  getWindowLocationHref(): string {
    return window.location.href;
  }

  setWindowLocationHref(href: string) {
    window.location.href = href;
  }

  setTimeout(handler: TimerHandler, timeout?: number): number {
    return window.setTimeout(handler, timeout);
  }

  setInterval(handler: TimerHandler, timeout?: number): number {
    return window.setInterval(handler, timeout);
  }

  clearInterval(id: number): void {
    return window.clearInterval(id);
  }
}
