import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginGuard} from './guards/login.guard';
import {WelcomeComponent} from './welcome/welcome.component';
import {AccountListComponent} from './accounts/account-list.component';
import {AccountDetailComponent} from './accounts/account-detail.component';
import {EbApplicationListComponent} from './eb-applications/eb-application-list.component';
import {EbApplicationDetailComponent} from './eb-applications/eb-application-detail.component';
import {ApplicationDatabaseDetailComponent} from './application-database/application-database-detail.component';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard]
  },
  {
    path: 'accounts',
    component: AccountListComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard]
  },
  {
    path: 'accounts/:id',
    component: AccountDetailComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard]
  },
  {
    path: 'ebApplications',
    component: EbApplicationListComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard]
  },
  {
    path: 'ebApplications/:applicationName',
    component: EbApplicationDetailComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard]
  },
  {
    path: 'ebApplications/:applicationName/database',
    component: ApplicationDatabaseDetailComponent,
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard]
  },
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
